import React, { useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {globalConfig} from '../globalConfig'

export default function Main() {
  const [birthdate, setBirthdate] = useState('');
  const [email, setEmail] = useState('');
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleConfirm = async () => {
    const dateParts = birthdate.match(/^(\d{2})\.(\d{2})\.(\d{4})$/);
    if (!dateParts) {
      toast.error('Некорректный формат даты');
      return;
    }

    const [, day, month, year] = dateParts;
    const dateObject = new Date(`${year}-${month}-${day}`);
    if (isNaN(dateObject.getTime())) {
      toast.error('Некорректная дата');
      return;
    }

    const currentDate = new Date();
    const nineMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 9));

    /* if (dateObject > nineMonthsAgo) {
      toast.error('не обманывай систему!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
   } */

    const birthdateISO = dateObject.toISOString();
    const postData = {
      email: email,
      date: birthdateISO
    };

    try {
      const response = await fetch(`${globalConfig.backendUrl}/form/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });

      if (!response.ok) {
        const errorText = await response.text();
        if (errorText.includes('Вы уже запланировали дату!')) {
          toast.error('Вы уже запланировали дату!');
          return;
        }
        if (errorText.includes('Слишком рано!')) {
          toast.error('Меньше 9 месяцев не бывает');
          return;
        }
        if (errorText.includes('Слишком поздно!')) {
          toast.error('Так поздно запрещается рожать детей!');
          return;
        }
        if (errorText === '') {
          navigate('/twopage');
        }
        throw new Error('Ошибка при отправке формы: ' + response.status + ' ' + errorText);
      }

      const result = await response.json();
      console.log('Ответ сервера:', result);
      navigate('/twopage'); 
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('Unexpected end of JSON input')) {
          navigate('/twopage');
        } else {
          toast.error(error.message || 'Произошла ошибка');
        }
      } else {
        toast.error('Произошла неизвестная ошибка');
      }
    }
  };
  
  const handleReset = async () => {
    const postData = {
      email: "kulaginaa818@gmail.com",
      date: "2030-02-14T00:00:00.000Z"
    };
  
    try {
      const response = await fetch(`${globalConfig.backendUrl}/form/reset`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error('Ошибка при сбросе данных: ' + response.status + ' ' + errorText);
      }
  
      const result = await response.json();
      console.log('Данные успешно сброшены:', result);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message || 'Произошла ошибка при сбросе данных');
      } else {
        console.error('Произошла неизвестная ошибка');
      }
    }
  };
  
  const handleBirthdateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Разрешаем только цифры и точки
    value = value.replace(/[^0-9.]/g, '');

    // Разделяем ввод по точкам
    const parts = value.split('.');

    // Формируем значение
    let formattedValue = '';

    // Первая часть (день) — максимум 2 цифры
    if (parts[0]?.length > 2) {
        parts[1] = parts[0].slice(2) + (parts[1] || '');
        parts[0] = parts[0].slice(0, 2);
    }
    formattedValue = parts[0];

    // Вторая часть (месяц) — максимум 2 цифры
    if (parts.length > 1) {
        if (parts[0].length === 2) {
            formattedValue += '.'; // Добавляем точку, если день введён
        }
        if (parts[1]?.length > 2) {
            parts[2] = parts[1].slice(2) + (parts[2] || '');
            parts[1] = parts[1].slice(0, 2);
        }
        formattedValue += parts[1] || '';
    }

    // Третья часть (год) — максимум 4 цифры
    if (parts.length > 2) {
        if (parts[1]?.length === 2) {
            formattedValue += '.'; // Добавляем точку, если месяц введён
        }
        formattedValue += parts[2]?.slice(0, 4) || '';
    }

    setBirthdate(formattedValue);
};


  return (
    <div className='main-container'>
      <ToastContainer />
      <div className='lib-header'>
        <div className='background'>
          <div className='lib-header-burger-btn'>
            <div className='horizontal-divider' />
            <div className='horizontal-divider-1' />
            <div className='horizontal-divider-2' />
          </div>
        </div>
        <span className='span-r'>р</span>
        <div className='lib-logo-link'>
          <div className='image-fill'>
            <div className='component'>
              <div className='vector' />
              <div className='vector-3' />
            </div>
          </div>
        </div>
      </div>
      <div className='rectangle'>
        <span className='heading-notification'>Уведомление</span>
        <span className='notification-text'>
          В рамках исполнения национального проекта "Демография" просим вас, как
          представителя высокоуважаемой семьи, безотлагательно заполнить
          предоставленную форму. Обращаем ваше внимание, что отказ от выполнения
          данного требования исключён. Ваша своевременная поддержка является
          значимым вкладом в будущее семьи и общества. <br /> <br />
          Сообщаем, что в случае непредоставления запрашиваемых данных в
          установленный срок к вам могут быть применены меры налогового
          стимулирования в виде повышенного налога на бездетность.
        </span>
        <span className='birthdate-input'>Введите дату рождения вашего будущего ребёнка:</span>
        <div className='frame'>
          <input
            className='frame-input'
            value={birthdate}
            onChange={handleBirthdateChange}
            placeholder='дд.мм.гггг'
          />
          <div
            onClick={() => setCalendarOpen(!calendarOpen)}
          >
            <div className='group' />
          </div>
        </div>
        <div className='frame-4'>
          <input
            className='frame-input-5'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Ваша почта'
          />
        </div>
        <button className="component-6" onClick={handleConfirm}>
          <span className="confirm-text">Подтвердить</span>
        </button>
      
      </div>
    </div>
  );
}
